import React from "react"
import Menu from "./menu"

const Layout = ({ children }) => {
  return (
    <div className="container mx-auto w-3/4 md:w-1/2">
      <Menu />
      <main>{children}</main>
    </div>
  )
}

export default Layout
