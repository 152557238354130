import { Link } from "gatsby"
import React from "react"

export default function Menu() {
  return (
    <nav className="pt-3 space-x-4">
      <Link to="/">Home</Link>
      <Link to="/articles/">Articles</Link>
    </nav>
  )
}
